import { useState } from "react";
import { http } from "../../utils/http";
type FetchConfig = {
  page?: any;
  results?: number;
};
const INITIAL_PAGE = 1;
export const useFetch = <T>(
  endpoint: string,
  config?: FetchConfig
): {
  isFetching: boolean;
  data: {
    count: number;
    results: T[];
  };
  fetch: (params?: any) => void;
  page: number;
} => {
  const [data, setData] = useState<any>({
    count: 0,
    results: [],
  });
  const [isFetching, setFetching] = useState<boolean>(true);
  const [page, setPage] = useState<number>(
    config && config.page ? config.page : INITIAL_PAGE
  );
  const fetch = async (params?: any) => {
    setFetching(!0);
    let _config: any = {};
    if (config && config.page) {
      _config["page"] = config.page;
    }
    let mergedParams = Object.assign({}, _config, params || {});
    if (mergedParams && mergedParams.page) {
      setPage(mergedParams.page);
    }
    let response = await http.get(endpoint, {
      params: mergedParams,
    });
    setFetching(!1);
    if (response && response.data) {
      setData(response.data);
    }
  };
  return {
    isFetching,
    data,
    fetch,
    page,
  };
};
