import { ErrorMessage } from "formik";
import React from "react";
import style from "./Select.module.scss";

type selectProps = {
  children: any;
  props?: any;
  name: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  label?: any;
  defaultValue?:any;
};
const SelectBox: React.FC<selectProps> = (props: any) => {
  return (
    <div className={style.select}>
      {props.label && <label>{props.label}</label>}
      <select {...props}>{props.children}</select>
      <div className={style.error}><ErrorMessage name={props.name}/></div>
    </div>
  );
};

export default SelectBox;
