import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getUser } from "../../services/user";
import { updateToken, getToken } from "../../utils/storage";

type UserModel = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};
export interface CounterState {
  user: UserModel | boolean;
  authChecking: boolean;
  token: string;
}
const initialState: CounterState = {
  user: false,
  authChecking: true,
  token: getToken() ? (getToken() as string) : "",
};
export const fetchUserAsync = createAsyncThunk("user/fetchUser", async () => {
  const response = await getUser();
  return response;
});
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => {},
    loggedIn: (state, action) => {
      let { payload } = action;
      if (payload && payload.key) {
        state.token = updateToken(payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state, action) => {
        state.authChecking = true;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        let { payload } = action;
        state.authChecking = false;
        if (payload.status === 401) {
          //   state.user = false;
        } else {
          state.user = payload.data as UserModel;
        }
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.authChecking = false;
      });
  },
});

export const { logout, loggedIn } = userSlice.actions;
export const getUserData = (state: RootState) => state.user;
export default userSlice.reducer;
