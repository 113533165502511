import React from 'react'
import style from "./Status.module.scss";

type statusProps ={
    status:boolean
}
const Status:React.FC<statusProps> = ({status}) => {
    const activeStatus = () => {
           return status ===true ? "Active" : "Inactive" ;
    }
  return (
    <div className={`${style.status} ${activeStatus() ==="Active"?style.active:style.inactive}`}>{activeStatus()}</div>
  )
}

export default Status