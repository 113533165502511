import {useState,useEffect} from 'react'
import { http } from "../../utils/http";

type dataProps ={
    endpoint:string
}
export const useData =<dataProps> (endpoint:any) => {
    useEffect(() => {
     fetch()
    }, [])
    
const[data,setData] = useState("");
 const fetch = async ()=>{
   let res = await http.get(endpoint);
   if(res && res.data){
    setData(res.data)
   }
 }
return data;
}