import React, { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import style from "./Action.module.scss";
import ClickAwayListener from "react-click-away-listener";

type ActionTypes = {
  edit?: any;
  remove?: any;
  left?: boolean;
};
const Action: React.FC<ActionTypes> = ({ edit, remove ,left}) => {
  const [action, setAction] = useState(false);
  const toggleAction = () => {
    setAction(!action);
  };
  return (
    <div className={`${style.action} ${left?style.left:""}`}>
      <span className={style.action_btn}>
        <BiDotsVerticalRounded onClick={toggleAction} />
      </span>
      {action && (
        <ClickAwayListener onClickAway={toggleAction}>
          <div className={style.actions_wrapper}>
            {edit && (
              <div className={style.actions} onClick={edit}>
                Edit
              </div>
            )}
            {remove && (
              <div className={style.actions} onClick={remove}>
                Delete
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Action;
