import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AddQuizComponent from '../../components/add-quiz-component'
import PageWrapper from '../../components/page-wrapper'
import { ENDPOINTS } from '../../const/endpoints';
import { useFetch } from '../../hooks/get';
import { http } from '../../utils/http';

const EditQuiz: React.FC = () => {
  const initial = {
    "id": 11,
    "title": "What is SOAP?",
    "slug": "what-is-soap",
    "description": "{\"time\":1663666587218,\"blocks\":[{\"type\":\"paragraph\",\"data\":{\"text\":\"aasdfs&nbsp; asdf ds as asd asdf asdf&nbsp;\"}}],\"version\":\"2.18.0\"}",
    "parent_question": null,
    "status": true,
    "is_child": false,
    "email_template":{
      "title":"hello",
      "from_name":"Own Aware",
      "from_address":"ownaware@gmail.com",
      "attachment_image":"https://images.idgesg.net/images/article/2018/08/01b-select-a-pdf-file-and-click-open-100767676-orig.jpg",
      "to_address":"sebin@gmail.com",
      "cc_address":"",
      "bcc_address":"",
      "subject":"trial",
      "description": "{\"time\":1663666587218,\"blocks\":[{\"type\":\"paragraph\",\"data\":{\"text\":\"aasdfs&nbsp; asdf ds as asd asdf asdf&nbsp;\"}}],\"version\":\"2.18.0\"}",
    },
    "evaluation_query": null,
    "tags": [{"id":1,"title":"test","description":"hellow","slug":"test"},{"id":2,"title":"testing tag","description":"oops","slug":"testing-tag"},{"id":3,"title":"TagsName","description":"","slug":"tagsname"},{"id":4,"title":"sebin","description":"","slug":"sebin"},{"id":5,"title":"siby","description":"","slug":"siby"}],
    "content_level": "",
    "core_category": "",
    "user_level": "",
    "content_type": "",
    "content_industry": "",
    "created": "2022-09-29T12:15:08.644432Z",
    "modified": "2022-09-29T12:15:08.644461Z",
    "ans_type": null,
    "has_child": true,
    "children": [
        {
            "id": 14,
            "title": "How it differs from REST?",
            "slug": "how-it-differs-from-rest",
            "description": "{\"time\":1665550181363,\"blocks\":[{\"type\":\"paragraph\",\"data\":{\"text\":\"aasdfs&nbsp; asdf ds as asd asdf asdf&nbsp;\"}},{\"type\":\"Image\",\"data\":{\"file\":{},\"caption\":\"\",\"withBorder\":false,\"stretched\":false,\"withBackground\":false}}],\"version\":\"2.18.0\"}",
            "evaluation_query": "",
            "status": true,
            "ans_type": null,
            "content_level": "",
            "core_category": "",
            "user_level": "",
            "content_type": "",
            "content_industry": ""
        },
        {
          "id": 14,
          "title": "Question2?",
          "slug": "how-it-differs-from-rest",
          "description": "{\"time\":1665550181363,\"blocks\":[{\"type\":\"paragraph\",\"data\":{\"text\":\"aasdfs&nbsp; asdf ds as asd asdf asdf&nbsp;\"}},{\"type\":\"Image\",\"data\":{\"file\":{},\"caption\":\"\",\"withBorder\":false,\"stretched\":false,\"withBackground\":false}}],\"version\":\"2.18.0\"}",
          "evaluation_query": "",
          "status": true,
          "ans_type": null,
          "content_level": "",
          "core_category": "",
          "user_level": "",
          "content_type": "",
          "content_industry": ""
      },
      {
        "id": 14,
        "title": "question3",
        "slug": "how-it-differs-from-rest",
        "description": "{\"time\":1665550181363,\"blocks\":[{\"type\":\"paragraph\",\"data\":{\"text\":\"aasdfs&nbsp; asdf ds as asd asdf asdf&nbsp;\"}},{\"type\":\"Image\",\"data\":{\"file\":{},\"caption\":\"\",\"withBorder\":false,\"stretched\":false,\"withBackground\":false}}],\"version\":\"2.18.0\"}",
        "evaluation_query": "",
        "status": true,
        "ans_type": null,
        "content_level": "",
        "core_category": "",
        "user_level": "",
        "content_type": "",
        "content_industry": ""
    }
    ]
};
  const params: any = useParams();
  const {
    data,
    page,
    isFetching,
    fetch,
  } = useFetch<any>(`${ENDPOINTS.quiz_single}/${params.id}`);
  useEffect(() => {
    fetch()
  }, [])
  if (isFetching) {
    return null
  }
  const INITIALVALUES = {
    ...data
  }
  return (
    <PageWrapper title='Quiz' breadcumbs={[{ title: "Assesment", link: "/assesment" }, { title: "Quiz", link: "/quiz" }, { title: "Edit quiz" }]}>
      <AddQuizComponent initialvalues={INITIALVALUES}/>
    </PageWrapper>
  )
}

export default EditQuiz