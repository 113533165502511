import React, { useState, useEffect } from "react";
import { useFetch } from "../../hooks/get";
import { Table } from "reactstrap";
import Pagination from "../pagination";
import { useQuery } from "../../hooks/query";
import { useLocation } from "react-router-dom";
import { CONFIG } from "../../config/config";
import style from "./Listing.module.scss";
interface ListingProps {
  endpoint: string;
  showPagination?: boolean;
  children: (data: any[]) => React.ReactNode;
}
const Listing: React.FC<ListingProps> = ({ endpoint, children }) => {
  const {
    data: { results, count },
    page,
    isFetching,
    fetch,
  } = useFetch<any>(endpoint);
  const location = useLocation();
  const query = useQuery();
  const buildSearchParams = (): any => {
    let _query = Object.fromEntries(query.entries());
    return _query;
  };
  useEffect(() => {
    let page = query.get("page") || 1;
    fetch({
      page,
      ...buildSearchParams(),
    });
  }, [location.key]);
  return (
    <div className={style.listing_content}>
      {isFetching && <div className={style.overlay}></div>}
      <Table bordered responsive className="table-wrapper">
        {children && "function" === typeof children ? children(results) : null}
      </Table>
      <div>
        {count && count > CONFIG.max_results && (
          <div>
            <Pagination activePage={page} count={count} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Listing;
