import { FieldArray, useFormikContext } from "formik";
import { set } from "immer/dist/internal";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ENDPOINTS } from "../../const/endpoints";
import { http } from "../../utils/http";
import Input from "../input";
import style from "./ParentOption.module.scss";

const Option = ({ index, helper, id }: any) => {
  const { values, setFieldValue }: any = useFormikContext();
  const [isAnswer, setIsAnswer] = useState(
    values?.options[index]?.is_right_option || false
  );
  const removeOption = (id: any) => {
    http.delete(`${ENDPOINTS.option}${id}`);
  };
  return (
    <div className="row">
      <div className="col-8">
        <Input
          type="text"
          name={`options[${index}].title`}
          value={values?.options[index]?.title}
          onChange={(e: any) => {
            setFieldValue(`options[${index}].title`, e.target.value);
          }}
        />
      </div>
      <div className="col-3 text-center">
        <input
          type="checkbox"
          name={`options[${index}].is_right_option`}
          checked={isAnswer}
          onChange={() => {
            setIsAnswer(!isAnswer);
            setFieldValue(`options[${index}].is_right_option`, !isAnswer);
          }}
        />
      </div>
      <div className="col-1">
        {values.options && values.options.length > 2 && (
          <span className={style.close_icon}>
            <AiOutlineClose
              onClick={() => {
                removeOption(id);
                helper.remove(index);
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

const ParentOption: React.FC = () => {
  const { values, setFieldValue }: any = useFormikContext();
  return (
    <div className={style.parent_option}>
      <FieldArray
        name="options"
        render={(arrayHelper) => (
          <div className="row">
            <div className="col-12">
              <div className="row mt-4 mb-3">
                <div className="col-8">
                  Enter your options and mark the right answer
                </div>
                <div className="col-3 text-center">Answer </div>
              </div>
            </div>
            <div className="col-12">
              {values.options &&
                values.options.length > 0 &&
                values.options.map((option: any, index: number) => {
                  return (
                    <div
                      className={style.option}
                      key={`parent_option ${index}`}
                    >
                      <Option
                        index={index}
                        helper={arrayHelper}
                        id={option.id}
                      />
                    </div>
                  );
                })}
              <div className="col-8 text-end">
                <span
                  className={style.add_option}
                  onClick={() => {
                    arrayHelper.push({
                      id: `temp_${
                        (values?.options &&
                          values.options.length > 0 &&
                          values.options.length + 1) ||
                        1
                      }`,
                      title: "",
                      is_right_option: false,
                    });
                  }}
                >
                  +Add option
                </span>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ParentOption;
