import { Formik } from 'formik';
import React, { useState } from 'react'
import AddQuizComponent from '../../components/add-quiz-component';
import Input from '../../components/input';
import PageWrapper from '../../components/page-wrapper';
import ToggleButton from '../../components/toggle-button';
import style from "./AddQuiz.module.scss";

// import { EDITOR_JS_TOOLS } from './tools';

const AddQuiz: React.FC = () => {

  return (
    <>
      <PageWrapper title="Quiz" breadcumbs={[{ title: "Assesment", link: "/assement" }, { title: "Quiz", link: "/Quiz" }, { title: "Add New" }]}>
        <AddQuizComponent />
      </PageWrapper>
    </>
  )
}

export default AddQuiz