import { Suspense, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserData } from "../hooks/user";
import AddQuiz from "../pages/add-quiz";
import EditQuiz from "../pages/edit-quiz";
import Quiz from "../pages/quiz";

const Login = lazy(() => import("../pages/login"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const QuestionAndAnswers = lazy(() => import("../pages/question-and-answers"));
const Roles = lazy(() => import("../pages/roles"));
const Tags = lazy(() => import("../pages/tags"));
const Appointments = lazy(()=>import("../pages/appontments"))
const ToolkitCategorys = lazy(()=>import("../pages/toolkit-categorys"))
const Companies = lazy(()=>import("../pages/companies"));
const Assesments = lazy(()=>import("../pages/assesments"));
const Routing = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/login" component={Login} />
      <ProtectedRoute
        exact
        path="/question-and-answers"
        component={QuestionAndAnswers}
      />
      <ProtectedRoute exact path="/roles" component={Roles} />
      <ProtectedRoute exact path="/tags" component={Tags} />
      <ProtectedRoute exact path="/appointments" component={Appointments} />
      <ProtectedRoute exact path="/toolkit-categorys" component={ToolkitCategorys} />
      <ProtectedRoute exact path="/companies" component={Companies} />
      <ProtectedRoute exact path="/assesments" component={Assesments} />
      <ProtectedRoute exact path="/quiz" component={Quiz} />
      <ProtectedRoute exact path="/quiz/add-quiz" component={AddQuiz} />
      <ProtectedRoute exact path="/quiz/edit-quiz/:id" component={EditQuiz} />
    </Suspense>
  );
};

const ProtectedRoute = ({ component: Component, path, ...props }: any) => {
  const USER = useUserData();
  return (
    <Route
      path={path}
      render={(props) => {
        let path = props.location.pathname;
        if (!USER.user && path !== "/login") {
          return <Redirect to="/login" {...props} />;
        }
        if (USER.user && path === "/login") {
          return <Redirect to="/" {...props} />;
        }
        if (props.match.isExact) {
          return <Component />;
        }
      }}
    />
  );
};
export default Routing;
