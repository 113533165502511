import axios , { AxiosResponse} from "axios";
import { ajaxHeaders } from "./headers";
import { httpParser } from "./httpParser";
import { removeToken } from "./storage";
export const http = axios.create({
    baseURL : 'https://ownaware.dev.fegno.com/api/v1/' ,
    validateStatus : (status)=>{
        if( status === 401 ){
            removeToken();
        }
        return !0 ;
    } 
}) ;

http.interceptors.response.use((res)=>{
    return httpParser( res ) as HttpResponse ;
});

http.interceptors.request.use(function( config ){
    config.headers = ajaxHeaders();
    return config ;
})