import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useState } from "react";
import Input from "../input";
import style from "./AddQuiz.module.scss";
import { INITIALVALUES, VALIDATION } from "./schema";
import { useData } from "../../hooks/get-single";
import Button from "../button";
import SubQuestions from "../sub-questions";
import "../../services/question";
import "../../services/question/option";
import { http } from "../../utils/http";
import { ENDPOINTS } from "../../const/endpoints";
import SelectBox from "../Select";
import { formatValues } from "./functions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { modules } from "../../const/quill";
import QuillViewer from "../quill-viewer";
import EmailTemplate from "../email-template";
import Option from "../option";
import ParentOption from "../parent-option";
import { optionCSS } from "react-select/dist/declarations/src/components/Option";
import { EMAILTEMPLATE_ATTACHMENT_TYPE } from "./const";
import { useHistory } from "react-router-dom";

type addQuizProps = {
  initialvalues?: any;
};
const AddQuizComponent: React.FC<addQuizProps> = ({ initialvalues }) => {
  const router = useHistory();
  const formatOption = (data: any) => {
    const formatted = data.map((item: any) => {
      return { label: item.slug, id: item.id };
    });
    return formatted;
  };
  const [hasEmail, setHasEmail] = useState(
    initialvalues?.email_template ? true : false
  );
  const [tags, setTag] = useState(
    initialvalues && initialvalues.tags ? initialvalues.tags : []
  ) as any;
  const tag: any = useData("administration/cms/tag/");
  const core_category: any = useData("administration/cms/corecategory/");
  const content_level: any = useData("administration/cms/contentlevel/");
  const user_level: any = useData("administration/cms/userlevel/");
  const content_type: any = useData("administration/cms/contenttype/");
  const content_industry: any = useData("administration/cms/contentindustry/");
  let tagsFormatted: any = {};
  tag &&
    tag.results &&
    tag.results.forEach((item: any) => {
      tagsFormatted[item.id] = item.title;
    });
  const formatTag = (data: any) => {
    const tag = data.map((item: any) => {
      return item.value;
    });
    return tag;
  };
  const formSubmit = async (values: any) => {
    if (!hasEmail) {
      values.email_template = null;
    }
    if (!values.has_child) {
      values.children = [];
    }
    if (values.has_child) {
      values.options = [];
    }
    const formattedValue = formatValues(values);
    let _url = ENDPOINTS.add_question;
    let res: any = null;
    if (!values.id) {
      res = await http.post(_url, { ...formattedValue, tags });
    } else {
      _url += `${values.id}/`;
      res = await http.patch(_url, { ...formattedValue, tags });
    }
    if(res&& res.status === 200){
      router.push('/quiz')
    }
  };

  /*
    :tags: variable which stores selected tags.
    :setTag: State Setter for :tags:
    :tagsFormatted: variable which store tagid as key and tag name as value.  // hashmap
    :tag: response of tags api; basically used with tag.results for list of tag data from server.

  */

  const onSelectChangeHandler = (e: any) => {
    tags.indexOf(e.target.value) > -1 ||
      setTag([...tags.concat(e.target.value)]);
    e.target.value = "-";
  };

  let handleTagRemove = (_tag: string) => {
    return (e: any) => {
      setTag(tags.filter((t: any) => t != _tag));
    };
  };

  const badgeTemplate = (_tag: any) => {
    return (
      <span className="badge bg-primary" key={tagsFormatted[_tag]}>
        {tagsFormatted[_tag]}
        <kbd
          className=""
          onClick={handleTagRemove(_tag)}
          style={{ cursor: "pointer" }}
        >
          ✕
        </kbd>
      </span>
    );
  };

  const _options =
    tag.results &&
    tag.results.map((opt: any, index: any) => {
      return (
        <option
          value={opt.id}
          disabled={tags.indexOf(opt.id.toString()) > -1}
          key={`${opt.title}${index}`}
        >
          {opt.title}
        </option>
      );
    });
  // const handleFileUpload = async (
  //   event: any,
  //   type: any,
  //   setFieldValue: any
  // ) => {
  //   let formData = new FormData();
  //   if(type==="image"){
  //     formData.append("image", event);
  //   }else{
  //     formData.append("file",event)
  //   }
  //   let res = await http.post(ENDPOINTS.media_upload, formData);
  //   if(res&&res.data&&res.data.file.url)
  //   if(type==="image"){
  //     setFieldValue(`email_template.attachment_image`,res.data.file.url)
  //   }else{
  //     setFieldValue(`email_template.attachment_file`,res.data.file.url)
  //   }
  // };
  const setInitialValues = (values: any) => {
    if (
      (values?.options?.length < 1 && !values.has_child) ||
      (!values.options && !values.has_child)
    ) {
      values.options = [
        { title: "", is_right_option: false, id: "temp_1" },
        { title: "", is_right_option: false, id: "temp_2" },
      ];
    }
    if (!values.email_template && values.has_email) {
      values.email_template = {
        id: "temp_1",
        title: "",
        from_name: "",
        from_address: "",
        attachment_image: "",
        attachment_file: "",
        to_address: "",
        cc_address: "",
        bcc_address: "",
        subject: "",
        description: "",
        attachment_type: "",
      };
    }
  };
  return (
    <div className={style.add_quiz_wrapper}>
      <Formik
        initialValues={initialvalues ? initialvalues : INITIALVALUES}
        validationSchema={VALIDATION}
        onSubmit={formSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            {setInitialValues(values)}
            <div className={`${style.section} ${style.question_type_decide}`}>
              <div className="row">
                <div className="col-6">
                  <div className={style.question}>
                    Would you like to have sub questions?
                  </div>
                  <div className={style.checkbox}>
                    <input
                      name="has_child"
                      type="checkbox"
                      id="sub_question"
                      onChange={handleChange}
                      checked={values.has_child}
                    />
                    <label htmlFor="sub_question">Have sub Questions</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className={style.question}>
                    Would you like to add an email template with this question?
                  </div>
                  <div className={style.checkbox}>
                    <input
                      type="checkbox"
                      id="has_email"
                      onChange={(e) => {
                        setHasEmail(e.target.checked);
                        setFieldValue(`has_email`, e.target.checked);
                      }}
                      defaultChecked={hasEmail}
                    />
                    <label htmlFor="has_email">Email template</label>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.parent_question} ${style.section}`}>
              <div className="row">
                <div className="col-6">
                  <div className={style.inner_title}>
                    {values.has_child ? "Create a scenario" : "Create question"}
                  </div>
                  <Input
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    label="Question"
                  />
                  <label>Description</label>
                  <div className={style.description}>
                    <ReactQuill
                      modules={modules}
                      value={values.description}
                      onChange={(e: any) => {
                        setFieldValue("description", e);
                      }}
                    />
                    <div className={style.error}>
                      {" "}
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                  {hasEmail && (
                    <div className={style.email_fields}>
                      <div className={style.inner_title}>
                        Customize email template
                      </div>
                      {/* <label>Attachment type</label>
                      <SelectBox
                        name="email_template.attachment_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.attachment_type &&
                          values.email_template.attachment_type
                        }
                      >
                        <option value="">-- Please select --</option>
                        {EMAILTEMPLATE_ATTACHMENT_TYPE.map(
                          (item: any, index: any) => {
                            return (
                              <option
                                key={item.title + index}
                                value={item.type}
                              >
                                {item.title}
                              </option>
                            );
                          }
                        )}
                      </SelectBox> */}
                      {/* {values &&
                        values.email_template &&
                        values.email_template.attachment_type &&
                        values.email_template.attachment_type !== "" &&
                        (values.email_template.attachment_type === "image" ? (
                          <Input
                            type="file"
                            name="email_template.attachment_image"
                            onChange={(e: any) => {
                              handleFileUpload(
                                e.target.files[0],
                                values.email_template.attachment_type,
                                setFieldValue
                              );
                            }}
                            label="Attachment image"
                          />
                        ) : (
                          <Input
                            type="file"
                            name="email_template.attachment_file"
                            onChange={(e: any) => {
                              handleFileUpload(
                                e.target.files[0],
                                values.email_template.attachment_type,
                                setFieldValue
                              );
                            }}
                            label="Attachment file"
                          />
                        ))} */}
                      <Input
                        name="email_template.from_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.from_name &&
                          values.email_template.from_name
                        }
                        label="From name"
                      />
                      <Input
                        name="email_template.from_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.from_address &&
                          values.email_template.from_address
                        }
                        label="From address"
                      />
                      <Input
                        name="email_template.to_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.to_address &&
                          values.email_template.to_address
                        }
                        label="To address"
                      />
                      <Input
                        name="email_template.cc_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.cc_address &&
                          values.email_template.cc_address
                        }
                        label="CC address"
                      />
                      <Input
                        name="email_template.bcc_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.bcc_address &&
                          values.email_template.bcc_address
                        }
                        label="BCC address"
                      />
                      <Input
                        name="email_template.subject"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values &&
                          values.email_template &&
                          values.email_template.subject &&
                          values.email_template.subject
                        }
                        label="Subject"
                      />
                      <label>Email body</label>
                      <div className={style.description}>
                        <ReactQuill
                          modules={modules}
                          value={values?.email_template?.description}
                          onChange={(e: any) => {
                            setFieldValue(`email_template.description`, e);
                          }}
                        />
                        <div className={style.error}>
                          <ErrorMessage name="email_template.description" />
                        </div>
                      </div>
                    </div>
                  )}
                  {!values.has_child && (
                    <div className={style.parent_option}>
                      <div className={style.inner_title}>Add options</div>
                      <ParentOption />
                    </div>
                  )}
                </div>
                {hasEmail && (
                  <div className="col-6">
                    <div className={style.inner_title}>Preview</div>
                    <div className={style.template}>
                      <div className={style.email_title}>{values.title}</div>
                      <div className={style.email_description}>
                        <QuillViewer value={values.description} noborder />
                      </div>
                      <EmailTemplate data={values.email_template} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {values.has_child && (
              <div className={style.section}>
                <SubQuestions
                  questions={
                    values &&
                    values.children &&
                    values.children.length > 0 &&
                    values.children
                  }
                />
              </div>
            )}
            <div className={`${style.category_list} ${style.section}`}>
              <div className={style.inner_title}>Title</div>
              <div className="row">
                <div className="col-12">
                  <label>Tags</label> <br />
                  <div className="mb-2">{[...tags].map(badgeTemplate)}</div>
                  <select
                    name={"tags"}
                    className="form-control"
                    onChange={onSelectChangeHandler}
                    defaultValue="-"
                  >
                    <option disabled value="-">
                      {" "}
                      - Please Select -{" "}
                    </option>
                    {_options}
                  </select>
                </div>
                <div className="col-6">
                  <SelectBox
                    label="Core categories"
                    name="core_category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.core_category?.id}
                  >
                    <option></option>
                    {core_category &&
                      core_category.results &&
                      core_category.results.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.id}
                            key={`core- ${item.title} ${index}`}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
                <div className="col-6">
                  <SelectBox
                    label="Content level"
                    name="content_level"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.content_level?.id}
                  >
                    <option></option>
                    {content_level &&
                      content_level.results &&
                      content_level.results.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.id}
                            key={`content-level${item.title}`}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
                <div className="col-6">
                  <SelectBox
                    label="User level"
                    name="user_level"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.user_level?.id}
                  >
                    <option></option>
                    {user_level &&
                      user_level.results &&
                      user_level.results.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.id}
                            key={`user-level ${item.title}`}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
                <div className="col-6">
                  <SelectBox
                    label="Content type"
                    name="content_type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.content_type?.id}
                  >
                    <option></option>
                    {content_type &&
                      content_type.results &&
                      content_type.results.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.id}
                            key={`content-type ${item.title}`}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
                <div className="col-6">
                  <SelectBox
                    label="Content industry"
                    name="content_industry"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.content_industry?.id}
                  >
                    <option></option>
                    {content_industry &&
                      content_industry.results &&
                      content_industry.results.map((item: any, index: any) => {
                        return (
                          <option
                            value={item.id}
                            key={`content-industry ${item.title}`}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
              </div>
            </div>
            <div className={style.btn_wrapper}>
              <Button type="submit">Submit</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddQuizComponent;
