import * as Yup from "yup";

export const INITIALVALUES = {
  title: "",
  has_child: false,
  has_email: false,
  description: "",
  ans_type: "",
  options: [
    // { title: "", is_right_option: false, id: "temp_1" },
    // { title: "", is_right_option: false, id: "temp_2" },
  ],
  email_template: {
    id: "temp_1",
    title: "",
    from_name: "",
    from_address: "",
    attachment_image: "",
    attachment_file: "",
    to_address: "",
    cc_address: "",
    bcc_address: "",
    subject: "",
    description: "",
    attachment_type: "",
  },
  children: [
    // {
    //   title: "",
    //   description: "",
    //   id: "temp_1",
    //   options: [
    //     { title: "", is_right_option: false, id: "temp_1" },
    //     { title: "", is_right_option: false, id: "temp_2" },
    //   ],
    // },
  ],
  tags: [],
  core_category: {},
  content_level: {},
  user_level: {},
  content_type: {},
  content_industry: {},
};
export const VALIDATION = Yup.object().shape({
  title: Yup.string().required("Enter Question"),
  description: Yup.string().required("Enter description"),
  has_child: Yup.boolean(),
  has_email: Yup.boolean(),
  options: Yup.array().when("has_child", {
    is: false,
    then: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().required("Enter option"),
        })
      )
      .min(2, "Add atleast two options"),
  }),
  children: Yup.array().when("has_child", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Enter title"),
        description: Yup.string().required("Enter description"),
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required("Enter option"),
            })
          )
          .min(2, "Add atleast two options"),
      })
    ),
  }),
  email_template: Yup.object()
    .when("has_email", {
      is: true,
      then: Yup.object().shape({
        from_name: Yup.string().required("Enter from name"),
        from_address: Yup.string().required("Enter from address"),
        to_address: Yup.string().required("Enter to address"),
        cc_address: Yup.string().required("Enter cc address"),
        bcc_address: Yup.string().required("Enter bcc address"),
        subject: Yup.string().required("Enter subject"),
        description: Yup.string().required("Enter description"),
      })
    })
    .when("has_email", {
      is: false,
      then: Yup.object().nullable(),
    }),
  // core_category:Yup.string().required('Select core category'),
  // content_level:Yup.string().required('Select core category'),
  // user_level:Yup.string().required('Select core category'),
  // content_type:Yup.string().required('Select core category'),
  // content_industry:Yup.string().required('Select core category'),
  // tags:Yup.object().required("Select Tags"),
});
