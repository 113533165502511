export const formatValues = (values: any) => {
  const core_category = values?.core_category?.id?values.core_category.id.toString():values.core_category;
  const content_level = values?.content_level?.id?values.content_level.id.toString():values.content_level;
  const user_level = values?.user_level?.id?values.user_level.id.toString():values.user_level;
  const content_type = values?.content_type?.id?values.content_type.id.toString():values.content_type;
  const content_industry = values?.content_industry?.id?values.content_industry.id.toString():values.content_industry;
  return {
    ...values,
    core_category,
    content_level,
    user_level,
    content_type,
    content_industry,
  };
};
