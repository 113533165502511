import Layout from "./layouts/layout";
import Routers from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    detection: {
      order: ["localStorage"],
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/transalation.json",
    },
  });
function App() {
  return (
    <div className="own-aware">
      <Router>
        <Layout>
          <Routers />
        </Layout>
      </Router>
      <ToastContainer theme="light"/>
    </div>
  );
}

export default App;
