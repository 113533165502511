const STORAGE_NAME = "_user_";
export const getToken = (): string | boolean => {
  let token = localStorage.getItem(STORAGE_NAME);
  if (token && token.trim()) {
    return token;
  }
  return !1;
};

export const updateToken = (data: { key: string },callback?:( key  :string )=>void ): string => {
  if (data && data.key) {
    localStorage.setItem(STORAGE_NAME, data.key);
  }
  callback && "function" === typeof callback && callback.call( null , data.key );
  return data.key;
};


export const removeToken = ()=>{
  localStorage.removeItem( STORAGE_NAME );
}