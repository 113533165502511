import React from "react";
import style from "./Header.module.scss";
const Header: React.FC = () => {
  const changeLanguage = () => {
    let lang: string | null = prompt("enter language en,ml or fr");
    if (!lang) return;
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };
  return (
    <div className={style.header}>
      {/* <button onClick={changeLanguage}>Change Language</button> */}
    </div>
  );
};
export default Header;
