import React, { useState } from "react";
import style from "./Input.module.scss";
import { ErrorMessage } from "formik";
import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { useFormikContext } from "formik";
import { capitalise } from "../../utils/capitaise" ;
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  ref?: any;
}
const Input: React.FC<InputProps> = React.forwardRef((props, ref) => {
  const [inputType, setType] = useState(props.type);
  const { errors }: any = useFormikContext();
  const toggleType = () => {
    if (inputType === "password") {
      setType("text");
    } else if (inputType === "text") {
      setType("password");
    } else {
      setType(props.type);
    }
  };
  return (
    <div className={style.input}>
      {props.label &&<label>{props.label}</label>}
      <div
        className={`${style.input_wrapper} ${
          errors[props.name] ? style.error : ""
        }`}
      >
        <input ref={ref} {...props} name={props.name} type={inputType} />
        {props.type === "password" && (
          <div onClick={toggleType} className={style.show_password}>
            {inputType === "password" ? <BiShow /> : <BiHide />}
          </div>
        )}
      </div>
      <div className={style.error}>
        <ErrorMessage
          name={props.name}
          render={(msg) => {
            return <span>{capitalise(msg)}</span>;
          }}
        />
      </div>
    </div>
  );
});
export default Input;
