import { getToken } from "./storage";
import { AxiosRequestHeaders } from "axios";
export const ajaxHeaders = (): AxiosRequestHeaders => {
  let token = getToken();
  if (token) {
    return {
      Authorization: `Token ${token}`,
    };
  }
  return {};
};
