import React, { useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { IoMdArrowDropdown, IoMdSkipForward } from "react-icons/io";
import { TiArrowForward } from "react-icons/ti";
import { formatDate } from "../../utils/dateFormat";
import AvatarDefaultName from "../avatar-default-name";
import QuillViewer from "../quill-viewer";
import style from "./EmailTemplate.module.scss";

type emailProps = {
  data: any;
};
const EmailTemplate: React.FC<emailProps> = ({ data }: any) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const toggleDetail = () => {
    setDetailOpen(!detailOpen);
  };
  if (!data) {
    return null;
  }
  return (
    <div className={style.email_template}>
      <div className={style.email_header}>
        <div className={style.user_data}>
          <AvatarDefaultName name={data.from_name ? data.from_name : ""} />
          <div className={style.user_data_wrapper}>
            <div className={style.header_name}>{data.from_name}</div>
            <div className={style.email_to}>
              <span className={style.text}>to me</span>{" "}
              <span className={style.icon_down}>
                <span onClick={toggleDetail}>
                  <IoMdArrowDropdown />
                </span>
                {detailOpen && (
                  <div className={style.details_popup}>
                    <div className="row">
                      <div className={`${style.titles} col-4`}>
                        <div>from:</div>
                        <div>reply-to:</div>
                        <div>to:</div>
                        <div>date:</div>
                        <div>subject:</div>
                        <div>mailed-by:</div>
                        <div>signed-by:</div>
                        <div>security:</div>
                      </div>
                      <div className="col-8">
                        <div>{data.from_address}</div>
                        <div>{data.from_address}</div>
                        <div>{data.to_address}</div>
                        <div>Oct 6, 2022, 10:35 AM</div>
                        <div>{data.subject}</div>
                        <div>{data.from_address}</div>
                        <div>{data.from_address}</div>
                        <div>Standard encryption (TLS) </div>
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className={style.extra_data}>
          <div className={style.time}>10:38 AM (0 Minutes ago)</div>
          <div>
            <AiOutlineStar />
          </div>
          <div>
            <TiArrowForward />
          </div>
          <div>
            <FiMoreVertical />
          </div>
        </div>
      </div>
      <div className={style.email_body}>
        <div className={style.description}>
        <QuillViewer value={data.description} noborder/>
        </div>
        {data.attachment_image && (
          <div className={style.bottom_image}>
            <img
              src={data.attachment_image}
              alt="attachment"
              className={style.attachment_image}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailTemplate;
