import React from "react";
import { Link } from "react-router-dom";
import style from "./Breadcumb.module.scss";
const Breadcumbs: React.FC<{ items: BreadCumbItem[] }> = ({ items }) => {
  return (
    <div className={style.breadcumb}>
      <ul className={style.breadcumb_list}>
        {items &&
          items.map((item, index: number) => {
            return (
              <li key={index}>
                {item.link && <Link to={item.link}>{item.title}</Link>}
                {!item.link && item.title}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default Breadcumbs;
