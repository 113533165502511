import React from 'react'
import ReactQuill from 'react-quill';
import style from "./Quill.module.scss";

type QuillProps={
    value:string;
    onChange?:any;
    theme?:string;
    defaultValue?:string;
    noborder?:boolean;
}
const QuillViewer: React.FC<QuillProps> = (props) => {
    return (
        <div className={`${style.quill} ${props.noborder?style.no_border:""}`}>
            <ReactQuill {...props} readOnly />
        </div>
    )
}

export default QuillViewer