import React from "react";
import Breadcumbs from "../breadcumb";
import style from "./Page.module.scss";

type PageWrapperProps = {
  title: string;
  children: React.ReactNode;
  breadcumbs?: BreadCumbItem[];
  options?: () => React.ReactNode;
};
const PageWrapper: React.FC<PageWrapperProps> = ({
  title,
  children,
  breadcumbs,
  options,
}) => {
  return (
    <div data-name={title} className={style.page_wrapper}>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <h4>{title}</h4>
            </div>
            {breadcumbs && breadcumbs.length > 0 && (
              <div>
                <Breadcumbs items={breadcumbs} />
              </div>
            )}
          </div>
        </div>
        {options && (
          <div className="col-6">
            {typeof options === "function" && options()}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};
export default PageWrapper;
