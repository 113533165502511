import React from "react";
import style from "./Spinner.module.scss";
import logo from "../../assets/logo.svg";
const Spinner: React.FC = () => {
  return <div className={style.spinner_wrapper}>
    <div className={style.spinner_inner_wrapper}>
      <div className={style.icon}><img src={logo} /></div>
      <div className={style.spinner}>
        <div className={style.spinner_inner}></div>
      </div>
    </div>
  </div>
};
export default Spinner;
