import React, { useEffect, useState } from "react";
import style from "./Sidebar.module.scss";
import { Collapse } from "reactstrap";
import logo from "../../assets/logo.svg";
import { MENU } from "../../const/menu";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { Link, Router, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
type menuItemProps = {
  item: any;
  reload: any;
  collapsed?: any;

};
const Sidebar: React.FC = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [num, setNum] = useState(1); 
  // const sidebarCollapse = () => {
  //   setSidebarCollapsed(!isSidebarCollapsed);
  // };
  const reload = () => {
    setNum(Math.random())
  }
  // const sidebar = document.getElementById("sidebar");
  // const onHover = () => {
  //  if(isSidebarCollapsed){
    
  //  }
  // }
  // window.addEventListener('mouseover', onHover)
  return (
    <div className={`${style.sidebar} ${isSidebarCollapsed ? style.collapsed : ""}`} id="sidebar">
      <div className={style.sidebar_header}>
        {!isSidebarCollapsed ? <img src={logo} alt="logo" className={style.logo} /> : ""}
        {/* <span
          className={`${style.sidebar_collapse_button} ${isSidebarCollapsed ? style.sidebar_collapsed : ""
            }`}
          onClick={sidebarCollapse}
        ></span> */}
      </div>
      <div className={style.sidebar_menus}>
        <ul className={style.menu_item_wrapper}>
          {MENU.map((item, index) => {
            return <MenuItem key={index} item={item} reload={reload} collapsed={isSidebarCollapsed} />;
          })}
        </ul>
      </div>
    </div>
  );
};

const MenuItem: React.FC<menuItemProps> = ({ item, reload, collapsed }: any) => {
  const [isOpen, toggle] = useState<boolean>(false);
  const [t] = useTranslation();
  const toggleState = () => {
    toggle(!isOpen);
  };
  const router = useHistory();
  return (
    <li className={style.sub_item_wrapper}>
      <div className={`${style.menu_item} ${item.link === router?.location?.pathname ? style.active : ""}`} onClick={reload}>
        {item && !item.children &&
          <Link to={item.link}>
            {item.icon}
            {!collapsed && (item.title.toLowerCase())}
          </Link>
        }
        {item && item.children && (
          <button onClick={toggleState} className={`${style.expand_btn} ${isOpen ? style.expanded : ""}`}>
            <span className={style.btn_title}>{item.icon}{!collapsed && item.title.toLowerCase()}</span>
            {!collapsed ? isOpen ? <FiChevronDown /> : <FiChevronRight /> : ""}
          </button>
        )}
      </div>
      {item && item.children && (
        <Collapse isOpen={isOpen}>
          <ul className={style.sub_items}>
            {item &&
              item.children.map((submenu: any, index: number) => {
                return (
                  <MenuItem item={submenu} key={`${item.title.split("").join("")}_${index}`} reload={reload} />
                );
              })}
          </ul>
        </Collapse>
      )}
    </li>
  );
};
export default Sidebar;
