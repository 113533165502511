import { http } from "../../../utils/http";
import { ENDPOINTS } from "../../../const/endpoints";


let save = async (payload: any) => {
  let res: HttpResponse;
  let _url = ENDPOINTS.option;
  if (payload.id) {
    _url += `${payload.id}/update/`;
    res = await http.post(ENDPOINTS.option, payload);
  } else {
    res = await http.patch(ENDPOINTS.option, payload);
  }
  return res;
};

let remove = async (payload: any) => {
    let _url = `${ENDPOINTS.option}/${payload.id}/delete/`
    let res: HttpResponse = await http.delete(_url, payload);
    return res;
  };

document.addEventListener("sub-question-saved", function(event: any){
    event.detail.options.map((data: any) => save(data))
})

document.addEventListener("sub-question-deleted", function(event: any){
    event.detail.children.map((data: any) => remove(data))
})

