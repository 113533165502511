import React from "react";
import style from "./Button.module.scss";
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  fullWidth?: boolean;
}
const Button: React.FC<ButtonProps> = ({ children, fullWidth, ...props }) => {
  return (
    <button
      className={`${style.button} ${props.disabled?style.disabled:""} ${fullWidth ? style.full_width : ""}`.trim()}
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;
