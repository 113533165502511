import React, { useState } from "react";
import Listing from "../../components/listing";
import PageWrapper from "../../components/page-wrapper";
import { ENDPOINTS } from "../../const/endpoints";
import style from "./Quiz.module.scss";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Status from "../../components/status";
import Action from "../../components/action";
import Button from "../../components/button";
import Sidebar from "../../components/sidebar";
import SidePanel from "../../components/side-panel";
import { Link, useHistory } from "react-router-dom";
import { http } from "../../utils/http";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const Quiz: React.FC = () => {
  const history = useHistory();
  const [addNew, setAddNew] = useState(false);
  const [random, setRandom] = useState(0);
  const reload = () => {
    window.location.reload()
  };
  const onEdit = (id: any) => {
    if (id) {
      history.push(`/quiz/edit-quiz/${id}`);
    }
  };
  const onDelete = (id: any) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            http.delete(`${ENDPOINTS.add_question} ${id}`);
            reload();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  console.log("quiz");
  return (
    <>
      <PageWrapper
        title="Quiz"
        breadcumbs={[
          { title: "Assesment", link: "/assesment" },
          { title: "Quiz" },
        ]}
      >
        {!addNew && (
          <>
            <div className={style.add_new}>
              <Button>
                <Link to="/quiz/add-quiz">
                  <a>Add New</a>
                </Link>
              </Button>
            </div>
            <div className={style.table_wrapper}>
              <Listing endpoint={ENDPOINTS.quiz}>
                {(results) => {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Question</th>
                          <th>Category</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results &&
                          results.map((item: any, index: any) => {
                            return (
                              <tr key={`${item.title}${index}`}>
                                <td>{index + 1}</td>
                                <td>{item.title ? item.title : ""}</td>
                                <td>
                                  {item.core_category
                                    ? item.core_category.title
                                    : "--"}
                                </td>
                                <td>{<Status status={item.status} />}</td>
                                <td>
                                  <span className={style.action_btn_wrapper}>
                                    <div className={style.action_wrapper}>
                                      <Action
                                        edit={() => {
                                          onEdit(item.id);
                                        }}
                                        remove={() => {
                                          onDelete(item.id);
                                        }}
                                      />
                                    </div>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </>
                  );
                }}
              </Listing>
            </div>
          </>
        )}
      </PageWrapper>
      {/* <SidePanel isOpen={sidebarOpen} onClose={() => { setSidebarOpen(false) }} size="large">
                <AddQuiz />
            </SidePanel> */}
    </>
  );
};

export default Quiz;
