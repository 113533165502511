import React, { useEffect } from "react";
import style from "./Layout.module.scss";
import { useAppDispatch } from "../../app/hooks";
import { fetchUserAsync } from "../../features/user/userSlice";
import { useUserData } from "../../hooks/user";
import { UserContext } from "../../context/UserContext";
import Spinner from "../../components/spinner";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import { CONFIG } from "../../config/config";

const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
  const dispatch = useAppDispatch();
  const user = useUserData();
  useEffect(() => {
    dispatch(fetchUserAsync());
  }, []);
  return (
    <div className={style.layout}>
      {user.authChecking ? (
        <Spinner />
      ) : (
        <UserContext.Provider value={user}>
          <div className={style.page}>
            {user.user && (
              <>
                <Sidebar />
              </>
            )}
            <div
              className={`${style.content} ${user.user ? style.logged_in : ""
                }`.trim()}
              id={CONFIG.pageId}
            >
              {user.user && <Header />}
              {props.children}
            </div>
          </div>
        </UserContext.Provider>
      )}
    </div>
  );
};
export default Layout;
