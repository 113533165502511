import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ReactQuill from "react-quill";
import { ENDPOINTS } from "../../const/endpoints";
import { modules } from "../../const/quill";
import { http } from "../../utils/http";
import Action from "../action";
import Button from "../button";
import Input from "../input";
import Option from "../option";
import style from "./SubQuestions.module.scss";

type SubQuestionProps = {
  questions: any;
};
const SubQuestions: React.FC<SubQuestionProps> = ({ questions }) => {
  const { values, setFieldValue, errors }: any = useFormikContext();
  const [qIndex, setIndex] = useState(0);
  if (values?.children?.length < 1) {
    values.children = [
      {
        title: "",
        description: "",
        options: [
          { id: "temp_1", title: "", is_right_option: false },
          { id: "temp_2", title: "", is_right_option: false },
        ],
      },
    ];
  }
  const removeOption = (id: any) => {
    http.delete(`${ENDPOINTS.option}${id}`);
  };
  return (
    <div className={style.sub_questions}>
      {/* {JSON.stringify(values.children)} */}
      <FieldArray
        name="children"
        render={(arrayHelpers) => (
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div
                    className={`${style.title} ${style.flex} ${style.container}`}
                  >
                    <span>Questions</span>
                    <Button
                      onClick={() => {
                        arrayHelpers.push({
                          id: `temp_${values.children.length + 1}`,
                          title: "",
                          description: "",
                          options: [
                            { title: "", is_right_option: false },
                            { title: "", is_right_option: false },
                          ],
                        });
                        setIndex(values.children.length);
                      }}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
                <div className="col-8">
                  <div
                    className={`${style.title} ${style.flex} ${style.container}`}
                  >
                    <span>New Question</span>
                    {values.children && values.children.length > 1 && (
                      <span>
                        <Action
                          remove={() => {
                            arrayHelpers.remove(qIndex);
                            setIndex(qIndex - 1);
                          }}
                          left
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div className={style.questions}>
                    {values.children &&
                      values.children.length > 0 &&
                      values.children.map((question: any, index: any) => {
                        return (
                          <div
                            className={`${style.question} ${
                              qIndex === index ? style.active : ""
                            }`}
                            onClick={() => {
                              setIndex(index);
                            }}
                            key={`${question.title} ${index}`}
                          >
                            {question.title || "Question " + (index + 1)}
                            {errors &&
                              errors.children &&
                              errors.children[index] && (
                                <span className={style.error}>*</span>
                              )}
                          </div>
                        );
                      })}
                    {values.children.length < 1 && (
                      <div className="text-center py-3">No questions added</div>
                    )}
                  </div>
                </div>
                <div className="col-8">
                  <div className={style.container}>
                    <div className={style.add_sub_question}>
                      <div className={style.input_wrapper}>
                        <label>Question</label>
                        <Input
                          name={`children[${qIndex}].title`}
                          value={
                            values?.children[qIndex]?.title
                              ? values.children[qIndex].title
                              : ""
                          }
                          onChange={(e: any) => {
                            setFieldValue(
                              `children[${qIndex}].title`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className={style.input_wrapper}>
                        <label>Description</label>
                        <ReactQuill
                          modules={modules}
                          value={
                            values?.children[qIndex]?.description
                              ? values.children[qIndex].description
                              : ""
                          }
                          onChange={(e: any) => {
                            setFieldValue(`children[${qIndex}].description`, e);
                          }}
                        />
                        <div className={style.error}>
                          <ErrorMessage
                            name={`children[${qIndex}].description`}
                          />
                        </div>
                      </div>
                      <div className={style.options_wrapper}>
                        <div className="row mt-4 mb-3">
                          <div className="col-8">
                            Enter your options and mark the right answer
                          </div>
                          <div className="col-3 text-center">Answer </div>
                        </div>
                        <FieldArray
                          name={`children[${qIndex}].options`}
                          render={(arrayHelpers) => (
                            <div>
                              {values?.children &&
                                values?.children[qIndex]?.options &&
                                values?.children[qIndex]?.options.length > 0 &&
                                values.children[qIndex].options.map(
                                  (option: any, index: any) => (
                                    <div
                                      key={`option ${index}`}
                                      className={style.option}
                                    >
                                      <div className="row">
                                        <div className="col-11">
                                          <Option
                                            qindex={qIndex}
                                            optionindex={index}
                                          />
                                        </div>
                                        <div className="col-1">
                                          {values?.children &&
                                            values?.children[qIndex] &&
                                            values?.children[qIndex]?.options &&
                                            values.children[qIndex].options
                                              .length > 2 && (
                                              <span
                                                className={style.close_icon}
                                              >
                                                <AiOutlineClose
                                                  onClick={() => {
                                                    removeOption(option.id);
                                                    arrayHelpers.remove(index);
                                                  }}
                                                />
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              <div className="row">
                                <div className="col-8 my-2">
                                  <div className={style.add_new_option}>
                                    <span
                                      onClick={() => {
                                        arrayHelpers.push({
                                          title: "",
                                          is_right_option: false,
                                          id: `${
                                            values?.children[qIndex]?.options
                                              .length + 1 || 1
                                          }`,
                                        });
                                      }}
                                    >
                                      + Add options
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default SubQuestions;
