import React from "react";
import ReactPaginate from "react-paginate";
import style from "./Pagination.module.scss";
import { useHistory } from "react-router-dom";
const RESULTS_PER_PAGE = 15;
type PaginationType = {
  count: number;
  activePage: number;
};
const Pagination: React.FC<PaginationType> = ({ count, activePage }) => {
  const history = useHistory();
  const onPageChange = (selectedItem: any) => {
    let _URL = buildUrl(selectedItem.selected + 1);
    history.push(`${_URL.pathname}${_URL.search}`);
  };
  const buildUrl = (page: number) => {
    let _URL = new URL(window.location.href);
    _URL.searchParams.set("page", `${page}`);
    return _URL;
  };
  const hrefBuilder = (page: any) => {
    return buildUrl(page).href;
  };
  const PAGES = Math.ceil(count / RESULTS_PER_PAGE);
  return (
    <div className={style.pagination}>
      <div className="text-center">
        Page <strong>{activePage}</strong> of <strong>{PAGES}</strong>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={PAGES}
        previousLabel="<"
        hrefBuilder={hrefBuilder}
        forcePage={activePage - 1}
      />
    </div>
  );
};
export default Pagination;
