import { AxiosResponse } from "axios";
export const httpParser = (res: AxiosResponse): HttpResponse => {
  let status = res.status;
  if (status >= 200 && status <= 300) {
    return {
      error: false,
      data: res.data,
      status,
    };
  }
  if (status === 500) {
    return {
      error: true,
      status,
    };
  }
  if (status >= 400 && status < 500) {
    let _res: any = {
      error: true,
      status,
    };
    if (res.data.non_field_errors) {
      _res.non_field_error = res.data.non_field_errors;
    } else {
      _res.field_error = res.data;
    }
    return _res;
  }
  return {
    status: 0,
    error: true,
  };
};
