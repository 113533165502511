import { useFormikContext } from "formik";
import { set } from "immer/dist/internal";
import React, { useState, useEffect } from "react";
import Input from "../input";

type optionProps = {
  qindex?: any;
  optionindex?: any;
};
const Option: React.FC<optionProps> = ({ qindex, optionindex }) => {
  const { values, setFieldValue }: any = useFormikContext();
  const [isAnswer, setIsAnswer] = useState(
    values?.children[qindex]?.options[optionindex]?.is_right_option
      ? values.children[qindex].options[optionindex].is_right_option
      : false
  );
  return (
    <div>
      <div className="row">
        <div className="col-9">
          <Input
            name={`children[${qindex}].options[${optionindex}].title`}
            value={values.children[qindex].options[optionindex].title}
            onChange={(e: any) => {
              setFieldValue(
                `children[${qindex}].options[${optionindex}].title`,
                e.target.value
              );
            }}
          />
        </div>
        <div
          className="col-3 text-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            onChange={() => {
              setIsAnswer(!isAnswer);
              setFieldValue(
                `children[${qindex}].options[${optionindex}].is_right_option`,
                !isAnswer
              );
            }}
            checked={
              values?.children[qindex]?.options[optionindex]?.is_right_option ||
              false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Option;
