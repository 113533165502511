export const ENDPOINTS = {
  user: "dj-rest-auth/user/",
  login: "dj-rest-auth/login/",
  create_question: "dj-rest-auth/login/",
  quiz:"administration/cms/question/",
  quiz_single:"administration/cms/question",
  roles: "administration/cms/role/",
  tag: "administration/cms/tag/",
  appointments: "administration/crm/appointment/",
  questions: "administration/cms/assistquestion/",
  toolkit_categorys:"administration/cms/toolkitcategory/" ,
  company : "administration/crm/company/",
  assessment : "administration/cms/assessment/",
  option: "administration/cms/questionoption/",
  add_question:"administration/cms/question/",
  media_upload:"../../cms/editor-js/media-upload/"
};