import { HiOutlineHome } from "react-icons/hi";
import { FiMail } from "react-icons/fi";
import { BsShieldCheck } from "react-icons/bs";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";
import { MdContentPaste } from "react-icons/md";
import { AiOutlineFileText } from "react-icons/ai";
import { HiOutlineSupport } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";

export const MENU = [
  { title: "Dashboard", link: "/", icon: <HiOutlineHome /> },
  { title: "Assist", link: "/assist/", icon: <FiMail /> },
  { title: "Protect", link: "/protect/", icon: <BsShieldCheck /> },
  { title: "Calender", link: "/calander/", icon: <AiOutlineCalendar /> },
  {
    title: "Customers",
    link: "/",
    icon: <BsPeople />,
    children: [
      { title: "Appointment", link: "/appointments/" },
      { title: "Leads", link: "/leads/" },
      { title: "Customers List", link: "/customers/" },
    ],
  },
  {
    title: "Billing",
    link: "/",
    icon: <FiMessageSquare />,
    children: [
      { title: "Approvals", link: "/approvals/" },
      { title: "Orders", link: "/orders/" },
      { title: "Return", link: "/return/" },
    ],
  },
  {
    title: "Content",
    link: "/",
    icon: <MdContentPaste />,
    children: [
      { title: "Awareness Videos", link: "/awarness" },
      { title: "Policies", link: "/policies" },
      { title: "Toolkit", link: "/toolkit" },
      { title: "Newsfeed", link: "/newsfeed" },
      { title: "Question & Answers", link: "/question-and-answers" },
      { title: "Companies", link: "/companies" },
    ],
  },
  {
    title: "Assesment",
    link: "/",
    icon: <AiOutlineFileText />,
    children: [
      { title: "Assesment", link: "/assesments" },
      { title: "Questions", link: "/quiz" },
      { title: "Invites", link: "/invites" },
    ],
  },
  {
    title: "Support",
    icon: <HiOutlineSupport />,
    link: "/",
    children: [
      {
        title: "Tickets",
        link: "/tickets",
        children: [{ title: "Ticket List", link: "/tickets-list" }],
      },
    ],
  },
  {
    title: "Settings",
    link: "/",
    icon: <FiSettings />,
    children: [
      { title: "Price & Plans", link: "/plans" },
      { title: "Plan packages", link: "/packages                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           " },
      // {
      //   title: "Global Data",
      //   link: "/",
      //   children: [
      //     { title: "Assist", link: "/" },
      //     { title: "Protect Actions", link: "/" },
      //     { title: "Protect Answers", link: "/" },
      //   ],
      // },
      // {
      //   title: "Users",
      //   link: "/",
      //   children: [
      //     { title: "Users List", link: "/" },
      //     { title: "User Groups", link: "/" },
      //   ],
      // },
      // { title: "Company Information", link: "/" },
      // { title: "Enable Modules", link: "/" },
      { title: "Roles", link: "/roles/" },
      { title: "Tags", link: "/tags/" },
      { title: "Toolkit Categorys", link: "/toolkit-categorys/" },
    ],
  },
];
